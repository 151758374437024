document.addEventListener('turbolinks:load', function() {
  var result = document.getElementById('result');
  result.style.display = 'none';
  result.style.visibility = 'visible';

  var loader = document.getElementById('loader');
  loader.style.display = 'none';

  //var form = document.forms[0];
  //form.onsubmit = function() {
  //  loader.style.display = 'block';
  //}

  var dismiss = document.getElementById('dismiss');
  dismiss.onclick = function() {
    result.style.display = 'none';
  }
});

document.addEventListener('ajax:aborted:required', function() {
  var loader = document.getElementById('loader');
  loader.style.display = 'none';
});
